'use client';
import type { Document } from '@contentful/rich-text-types';
import { clsx } from 'clsx';
import type { ArticleType } from '@pyra/docs-shared/types';
import type { JSX } from 'react';
import { BackLink } from '@pyra/docs-shared/components/nav';
import type { ExploreCard } from '#/lib/contentful/blog';
import { textBlock } from '#/lib/contentful/renderers/components';
import styles from './slug.module.css';

// Make contentful data fit into the type that the react components
// expect
export interface GuidePost {
  title: string;
  description: string;
  seoTitle: string | null;
  seoDescription: string | null;
  slug: string;
  createdAt: string;
  type: ArticleType;
  rank: number;
  content: Document;
  topics: {
    title: string;
    description: string;
    slug: string;
  }[];
  authors: {
    name: string;
    isMemberOfVercelTeam: boolean;
    slug: string;
  }[];
  exploreCards: ExploreCard[];
}

interface GuidePageProps {
  article: GuidePost;
}

function GuidePage({ article }: GuidePageProps): JSX.Element {
  return (
    <>
      <BackLink home="Guides" path="/guides" />
      <div className={styles.headerTitleGuidesPage}>
        <h1 className={clsx('knowledge-title')}>{article.title}</h1>
      </div>
      <section className={clsx('knowledge-content')}>
        <article>{textBlock(article.content)}</article>
      </section>
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default GuidePage;
