import type { ReactNode, JSX } from 'react';
import React from 'react';
import type { Block, Document, Inline } from '@contentful/rich-text-types';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@pyra/contentful/utils/document-to-react-components';
import { Text } from 'geist/components';
import { assetHandler } from '../helpers/asset-handler';
import styles from '../index.module.css';
// eslint-disable-next-line import/no-cycle -- refactored from one single giant file
import { EmbeddedEntryBlock } from './embedded-entry-block';
import { components } from './component-list';
import { EmbeddedEntryInline } from './embed-entry-inline';

function Ol(props: { children?: React.ReactNode }): JSX.Element {
  return (
    <ol {...props} className="my-4 ml-3.5 list-decimal p-0 pl-4">
      {props.children}
    </ol>
  );
}

function getElementName({ type }: React.ReactElement): string {
  if (typeof type === 'string') {
    return type;
  }
  return type.name || 'Unknown';
}

function getDataUri(node: Block | Inline): string {
  if ('uri' in node.data) {
    return (node.data as { uri: string }).uri;
  }
  return '';
}

export function stripParagraphFromListItem(
  node: ReactNode,
): ReactNode[] | null {
  if (!Array.isArray(node)) {
    return null;
  }
  return node.map((child: React.ReactElement<{ children: ReactNode }>) => {
    if (typeof child === 'string') {
      return null;
    }
    // Strip wrapping paragraphs, but not wrapping UL
    return getElementName(child) === 'UL' ? child : child.props.children;
  });
}

export const options: Options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline): JSX.Element | null => {
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- TODO: Fix ESLint Error (#13355) */
      return assetHandler(node.data.target.fields);
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline): JSX.Element | null => {
      return EmbeddedEntryBlock(node);
    },
    [INLINES.EMBEDDED_ENTRY]: EmbeddedEntryInline,
    [BLOCKS.PARAGRAPH]: (
      _node: Block | Inline,
      children: ReactNode,
    ): JSX.Element => <p className={styles.paragraph}>{children}</p>,
    [INLINES.HYPERLINK]: (
      node: Block | Inline,
      children: ReactNode,
    ): JSX.Element => {
      return (
        <components.Link href={getDataUri(node)}>{children}</components.Link>
      );
    },
    [BLOCKS.UL_LIST]: (
      _node: Block | Inline,
      children: ReactNode,
    ): JSX.Element => <components.UL>{children}</components.UL>,
    [BLOCKS.OL_LIST]: (
      _node: Block | Inline,
      children: ReactNode,
    ): JSX.Element => {
      return <Ol>{children}</Ol>;
    },
    [BLOCKS.LIST_ITEM]: (
      _node: Block | Inline,
      children: ReactNode,
    ): JSX.Element | null => {
      // We know for sure that Contentful always wraps list items
      // in paragraphs, so we'd like to delete those
      if (!Array.isArray(children)) {
        return null;
      }
      return (
        <components.LI className={styles.listItem}>
          {stripParagraphFromListItem(children)}
        </components.LI>
      );
    },
    [BLOCKS.HEADING_1]: (
      _node: Block | Inline,
      children: ReactNode,
    ): JSX.Element => (
      <components.Heading lean>
        <Text as="h1" size={40}>
          {children}
        </Text>
      </components.Heading>
    ),
    [BLOCKS.HEADING_2]: (
      _node: Block | Inline,
      children: ReactNode,
    ): JSX.Element => (
      <components.Heading containerStyle={false} lean>
        <Text as="h2" className={styles.heading2} size={32} weight={700}>
          {children}
        </Text>
      </components.Heading>
    ),
    [BLOCKS.HEADING_3]: (
      _node: Block | Inline,
      children: ReactNode,
    ): JSX.Element => (
      <components.Heading containerStyle={false} lean>
        <Text as="h3" className={styles.heading3} size={24}>
          {children}
        </Text>
      </components.Heading>
    ),
  },
  renderMark: {
    [MARKS.CODE]: (text: ReactNode): JSX.Element => (
      <components.InlineCode fontSize="0.9em">{text}</components.InlineCode>
    ),
  },
};

export function textBlock(
  content: Document,
  forceGeist?: boolean,
  pure?: boolean,
): ReactNode[] {
  let opt = options;
  if (forceGeist) {
    const { Text: TextComponent } = components;
    const Component = pure ? 'span' : TextComponent;
    opt = {
      ...opt,
      renderNode: {
        ...opt.renderNode,
        [BLOCKS.PARAGRAPH]: (
          _node: Block | Inline,
          children: ReactNode,
        ): JSX.Element => <Component>{children}</Component>,
      },
    };
  }
  // documentToReactComponents can return an array, which is undocumented and causes all sorts of typescript headaches!
  const renderedComponents = documentToReactComponents(content, opt) as
    | ReactNode
    | ReactNode[];

  if (!Array.isArray(renderedComponents)) {
    return [renderedComponents];
  }

  return renderedComponents;
}
