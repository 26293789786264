// This component is deprecated, but we need to move it to the shared package, as it's being used in preview comments
import React, { memo } from 'react';
import { clsx } from 'clsx';

/* eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: Fix ESLint Error (#13355) */
interface Props {
  x?: number;
  y?: number;
  expand?: boolean;
  className?: string;
  inline?: boolean;
  padding?: boolean;
}

/**
 * Creates space between elements by inserting a `span`.
 *
 * @deprecated This is non-semantic, and adds unnecessary weight to the DOM
 * tree, which can have a real performance impact (https://web.dev/dom-size/).
 * Instead, use:
 * - The `Stack` component from `@geist/stack`
 * - `gap` with `flex` and `grid` (https://developer.mozilla.org/en-US/docs/Web/CSS/gap).
 * - `margin` (or `padding`) for other display types.
 */
/* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
export const Spacer: React.FC<Props> = ({
  x = 1,
  y = 1,
  expand,
  className,
  inline,
  padding,
}) => {
  return (
    <span
      aria-hidden="true"
      className={clsx('geist-spacer', className, { padding, inline, expand })}
      data-version="v1"
      style={{
        marginLeft: x !== 1 ? x * 24 - 1 : undefined,
        marginTop: y !== 1 && !inline ? y * 24 - 1 : undefined,
        paddingLeft: padding ? x * 24 - 1 : undefined,
        paddingTop: padding && !inline ? y * 24 - 1 : undefined,
      }}
    />
  );
};

Spacer.displayName = 'Spacer';
/**
 * Creates space between elements by inserting a `span`.
 *
 * @deprecated This is non-semantic, and adds unnecessary weight to the DOM
 * tree, which can have a real performance impact (https://web.dev/dom-size/).
 * Instead, use:
 * - The `Stack` component from `@geist/stack`
 * - `gap` with `flex` and `grid` (https://developer.mozilla.org/en-US/docs/Web/CSS/gap).
 * - `margin` (or `padding`) for other display types.
 */
/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default memo(Spacer);
