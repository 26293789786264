import { getHeadingSidebarText } from './get-heading-sidebar-text';

export function getHeadingId(heading: string): string {
  const headingText = getHeadingSidebarText(heading.toString());
  return `${headingText
    .replace(/[?]/g, '')
    .replace(/"|,|→|\[object-object\]/g, '') //This removes any instances of `,`, `→`, `[object-object]` in the heading text before it's used for the id - [object-object] sometimes happens if the react object was not parsed correctly
    .replace(/ /g, '-')
    .toLowerCase()}`;
}
