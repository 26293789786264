'use client';
import type { Block, Inline, Text } from '@contentful/rich-text-types';
import type { JSX } from 'react';
import type { CodeNode } from '../../../types';
import styles from '../../index.module.css';
import { MediaCaption } from '../media-caption';
import { components } from '../component-list';

function isBlockOrInline(item: Block | Inline | Text): item is Block | Inline {
  return 'content' in item;
}

function isText(item: Block | Inline | Text): item is Text {
  return 'value' in item;
}

export function Code(props: CodeNode): JSX.Element {
  const {
    content: { content },
    language,
    caption,
    highlightedLines,
    fileName,
  } = props.data.target.fields;

  const full = (content as (Block | Inline | Text)[]).reduce(
    (accumulator: string, currentValue: Block | Inline | Text) => {
      if (isBlockOrInline(currentValue)) {
        const textPieces = (
          currentValue.content as (Block | Inline | Text)[]
        ).reduce((final: string, active: Block | Inline | Text) => {
          return isText(active) ? final + active.value : final;
        }, '');
        const prefix = accumulator ? '\n\n' : '';

        return accumulator + prefix + textPieces;
      }
      return accumulator;
    },
    '',
  );

  const highlightedLinesNumbers: number[] = highlightedLines
    ? highlightedLines.map((l) => parseInt(l))
    : [];

  return (
    <div className={styles.codeWrapper} data-vercel-edit-target>
      <components.Code
        filename={fileName}
        highlightedLinesNumbers={highlightedLinesNumbers}
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Contentful claims this is never undefined, but I don't think I believe them.
        language={(language || '').toLowerCase()}
        textToCopy={full}
      >
        {full}
      </components.Code>

      {caption ? <MediaCaption>{caption}</MediaCaption> : null}
    </div>
  );
}
