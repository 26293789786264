import type { JSX } from 'react';
import { assetHandler } from '../../helpers/asset-handler';
import type { MobileAndModeDependentMediaNode } from '../../../types';
import { components } from '../component-list';

export function MobileAndModeDependentMedia(
  props: MobileAndModeDependentMediaNode,
): JSX.Element | null {
  const {
    lightMobileVersion: { fields: lightMobileVersionFields },
    darkMobileVersion: { fields: darkMobileVersionFields },
    lightDesktopVersion: { fields: lightDesktopVersionFields },
    darkDesktopVersion: { fields: darkDesktopVersionFields },
    dottedBackground,
    imagePercentageWidth,
    fullWidth,
  } = props.data.target.fields;

  const imageConfig = {
    dottedBackground,
    imagePercentageWidth,
    fullWidth,
  };

  const isVideoAndHasAllFields =
    lightDesktopVersionFields.file.contentType.startsWith('video') &&
    lightDesktopVersionFields.file.url &&
    darkDesktopVersionFields.file.contentType.startsWith('video') &&
    darkDesktopVersionFields.file.url &&
    lightMobileVersionFields.file.contentType.startsWith('video') &&
    lightMobileVersionFields.file.url &&
    darkMobileVersionFields.file.contentType.startsWith('video') &&
    darkMobileVersionFields.file.url;

  if (
    !isVideoAndHasAllFields &&
    (!lightDesktopVersionFields.file.details.image ||
      !darkDesktopVersionFields.file.details.image ||
      !lightMobileVersionFields.file.details.image ||
      !darkMobileVersionFields.file.details.image)
  ) {
    return null;
  }

  return (
    <components.ViewportHelper
      desktop={{
        light: assetHandler(lightDesktopVersionFields, imageConfig),
        dark: assetHandler(darkDesktopVersionFields, imageConfig),
      }}
      mobile={{
        light: assetHandler(lightMobileVersionFields, imageConfig),
        dark: assetHandler(darkMobileVersionFields, imageConfig),
      }}
    />
  );
}
