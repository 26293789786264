import type { ReactNode, JSX } from 'react';
import React, { Children, isValidElement } from 'react';
import dynamic from 'next/dynamic';
import {
  Button,
  Image,
  Collapse,
  CollapseGroup,
  Snippet,
  Note,
  Kbd,
  Text,
  Spacer,
  ContainerStack,
} from 'geist/components';
import { Link as MultiZoneLink } from '@pyra/multi-zone/link';
import { handleImport } from '@pyra/promise-utils/handle-import';
import { UL, LI } from '#/app/components/list/app';
import Caption from '#/app/components/text/caption';
import Heading from '#/app/components/text/linked-heading';
import { InlineCode } from '#/app/components/guides/code';
import Card from '#/app/components/card';
import ThemeHelper, {
  ViewportHelper,
} from '#/app/components/guides/theme-helper';
import styles from '#/lib/mdx-components.module.css';

interface LinkProps {
  children: ReactNode;
  href: string;
}

function Link(props: LinkProps): JSX.Element {
  const hasCode = Children.toArray(props.children).some((child: ReactNode) => {
    if (isValidElement(child) && typeof child.type !== 'string') {
      return child.type.name === 'InlineCode';
    }
    return false;
  });

  if (hasCode) {
    return <MultiZoneLink className={styles.inlineCodeLink} {...props} />;
  }

  return <MultiZoneLink variant="highlight" {...props} />;
}

function OL(props: { children?: React.ReactNode }): JSX.Element {
  return (
    <ol {...props} className="my-4 ml-3.5 list-decimal p-0 pl-4">
      {props.children}
    </ol>
  );
}

export const components = {
  Code: dynamic(() =>
    handleImport(
      import('geist/components/code-block').then((mod) => ({
        default: mod.CodeBlock,
      })),
    ),
  ),
  CodeSandbox: dynamic(() => handleImport(import('./sandbox'))),
  Image,
  Caption,
  Collapse,
  CollapseGroup,
  Button,
  MultiZoneLink,
  UL,
  LI,
  OL,
  Container: ContainerStack,
  ThemeHelper,
  ViewportHelper,
  /* eslint-disable-next-line import/no-named-as-default-member -- TODO: Fix ESLint Error (#13355) */
  Fragment: React.Fragment,
  Table: dynamic(() =>
    handleImport(import('#/styleguide/table')).then((mod) => ({
      default: mod.Table,
    })),
  ),
  TableCell: dynamic(() =>
    handleImport(import('#/styleguide/table')).then((mod) => ({
      default: mod.TableCell,
    })),
  ),
  TableHead: dynamic(() =>
    handleImport(import('#/styleguide/table')).then((mod) => ({
      default: mod.TableHead,
    })),
  ),
  TableRow: dynamic(() =>
    handleImport(import('#/styleguide/table')).then((mod) => ({
      default: mod.TableRow,
    })),
  ),
  Note,
  Text,
  Tree: dynamic(() =>
    handleImport(import('@pyra/components/file-tree')).then((mod) => ({
      default: mod.Tree,
    })),
  ),
  Folder: dynamic(() =>
    handleImport(import('@pyra/components/file-tree')).then((mod) => ({
      default: mod.Folder,
    })),
  ),
  File: dynamic(() =>
    handleImport(import('@pyra/components/file-tree')).then((mod) => ({
      default: mod.File,
    })),
  ),
  InlineCode,
  Kbd,
  Snippet,
  Spacer,
  Card,
  Heading,
  Link,
} as const;
