import type { JSX } from 'react';
import { Link } from '@pyra/multi-zone/link';

export interface BacklinkProps {
  home: string;
  path: string;
}

export function BackLink({ home, path }: BacklinkProps): JSX.Element {
  return (
    <div className="h-[48px]">
      <Link href={path} type="secondary">
        <small>← Back to {home}</small>
      </Link>
    </div>
  );
}
