import { clsx } from 'clsx';
import { useDisabled } from '#/lib/use-disabled-context';
import theme from './syntax-theme.module.css';
import styles from './code.module.css';

export const Code: React.FunctionComponent<{
  syntax: string;
  lite?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
  /* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
}> = ({ children, syntax, lite, style }) => (
  <pre
    className={clsx(styles.pre, syntax, {
      [styles.lite]: lite,
    })}
    style={style}
  >
    <code className={theme.code}>{children}</code>
  </pre>
);

export const InlineCode: React.FunctionComponent<{
  noWrap?: boolean;
  fontSize?: string;
  children: React.ReactNode;
  /* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
}> = ({ children, noWrap, fontSize, ...props }) => {
  const disabled = useDisabled();

  return (
    <code
      className={clsx(styles.code, {
        [styles['no-wrap']]: noWrap,
        [styles.disabled]: disabled,
      })}
      style={{ fontSize }}
      {...props}
    >
      {children}
    </code>
  );
};
