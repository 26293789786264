import styles from './caption.module.css';

/* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
const Caption: React.FC<{ children?: React.ReactNode }> = (props) => (
  <figcaption className={styles.caption} {...props} />
);
/* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
const Code: React.FC = (props) => <code className={styles.code} {...props} />;

const pkg = Object.assign(Caption, { Code });
/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default pkg;
