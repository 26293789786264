import type { JSX } from 'react';
/* eslint-disable-next-line import/no-cycle -- refactored from single giant file */
import { textBlock } from '..';
import type { NoteNode } from '../../../types';
import { stripWrappingParagraph } from '../../helpers/strip-wrapping-paragraph';
import { components } from '../component-list';

export function Note(props: NoteNode): JSX.Element {
  const { Note: NoteComponent } = components;
  const doc = { ...props.data.target.fields.content };

  // Strip away the wrapping paragraph
  stripWrappingParagraph(doc);
  return <NoteComponent>{textBlock(doc)}</NoteComponent>;
}
