'use client';
import type { PropsWithChildren, JSX } from 'react';
import { useState, useCallback } from 'react';
import { Provider } from './collapse-context';
import styles from './collapse.module.css';

function CollapseGroup({
  children,
  ...props
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>): JSX.Element {
  const [selected, setSelected] = useState('');

  const onChange = useCallback((title: string) => {
    setSelected(title);
  }, []);

  return (
    <div className={styles.collapseGroup} data-version="v1" {...props}>
      <Provider
        value={{
          /* eslint-disable-next-line object-shorthand -- TODO: Fix ESLint Error (#13355) */
          selected: selected,
          /* eslint-disable-next-line object-shorthand -- TODO: Fix ESLint Error (#13355) */
          onChange: onChange,
        }}
      >
        {children}
      </Provider>
    </div>
  );
}

/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default CollapseGroup;
