import { useMemo, type JSX } from 'react';
import { vercelStegaSplit } from '@vercel/stega';
import type { CodeSandboxNode } from '../../../types';
import type { CodeSandboxPayload } from '../../../payloads';
import styles from '../../index.module.css';
import { MediaCaption } from '../media-caption';
import { components } from '../component-list';
import { getAssetURL } from '../../helpers/get-asset-url';

export function CodeSandbox(node: CodeSandboxNode): JSX.Element {
  const { CodeSandbox: CodeSandboxComponent } = components;
  // The payload includes many more properties than I've handtyped here.
  // I've handtyped strictly the stuff that we need.
  const sandboxNode = node as CodeSandboxPayload;
  const templateType: 'react' | 'react-ts' =
    sandboxNode.data.target.fields.language?.[0] ?? 'react';
  const caption = sandboxNode.data.target.fields.caption;

  const staticAssets = useMemo(
    () =>
      sandboxNode.data.target.fields.assets?.map(
        (asset: { fields: { file: { url: string } } }) => {
          return getAssetURL(asset.fields.file.url, 'image');
        },
      ) ?? [],
    [sandboxNode.data.target.fields.assets],
  );

  const mapDataToSandbox = useMemo(
    () =>
      Object.fromEntries(
        sandboxNode.data.target.fields.files.map((file) => {
          const fileName = vercelStegaSplit(file.fields.caption).cleaned;
          // This refers to if the file is "hidden" in Sandpack.
          // End users won't see these files as a tab in the editor.
          const fileHidden = Boolean(file.fields.highlightedLines?.length);

          const lines = file.fields.content.content.map((elem) => {
            const code = elem.content.reduce((acc, cur) => {
              return acc + cur.value;
            }, '');

            return code;
          });

          // Contentful will return different values depending on
          // if you use ENTER or SHIFT+ENTER.
          // This fixes ENTERs.
          const code = vercelStegaSplit(lines.join('\n\n')).cleaned;

          // Sandpack does not allow for any way to host static assets.
          // Therefore, we are going to host them through Contentful + Cloudinary.
          // The code below will:
          // 1. Handle ai wildcard in the strings that come from Contentful.
          // 2. Handle asset hosting for any assets in the Contentful payload.
          // 3. Replace the wildcards with paths from our host.

          // The *▲* in the regex is a wildcard in the code entries from Contentful
          // that we will be replacing with the images paths from Cloudinary.
          // We will replace *▲*n*▲* with the Cloudinary path.
          const regex = /\*▲\*[0-9]+\*▲\*/g;

          // Create an array of indices from Contentful input.
          // Note that these can be unordered. Ex: [3, 0, 1, 2]
          const regexMatchedIndex = (match: string): number =>
            Number(/[0-9]+/.exec(match));

          // All wildcard token matches.
          const assetMatches = code.match(regex);

          // Create the final string, replacing tokens with hosted asset URLs.
          const finalString = (
            codeString: string,
            matches: RegExpMatchArray | null,
          ): string => {
            if (!matches) return codeString;

            const assetIndices = matches.map((match) =>
              regexMatchedIndex(match),
            );

            // Create an object that we will access
            // when we map over the tokens to replace.
            const assetUrlsObj = Object.fromEntries(
              assetIndices.map((assetIndex) => [
                assetIndex,
                staticAssets[assetIndex],
              ]),
            );

            // Replace the tokens!
            const withReplacements = codeString.replace(regex, (matched) => {
              return assetUrlsObj[regexMatchedIndex(matched)];
            });

            return withReplacements;
          };

          return [
            fileName,
            { code: finalString(code, assetMatches), hidden: fileHidden },
          ];
        }),
      ),
    [sandboxNode.data.target.fields.files, staticAssets],
  );

  return (
    <div className={styles.codesandbox}>
      <figure>
        <CodeSandboxComponent
          dependencies={sandboxNode.data.target.fields.dependencies}
          files={mapDataToSandbox}
          showConsoleButton
          template={templateType}
        />
      </figure>
      {caption ? <MediaCaption>{caption}</MediaCaption> : null}
    </div>
  );
}
