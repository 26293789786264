import type { TopLevelBlock, Document } from '@contentful/rich-text-types';

/**
 * Removes a wrapping paragraph element that Contentful includes in everything.
 * Type assertion in the function is from refactoring from legacy code that uses any.
 * The function we use in `textBlock` (`documentToReactComponents`) can still correctly parse
 * this object after we remove the wrapper.
 *
 * TODO: write a unit tests that ensures that stays the case.
 */
export function stripWrappingParagraph(doc: Document): void {
  doc.content = doc.content[0].content as unknown as TopLevelBlock[];
}
