import type { ReactNode, JSX } from 'react';
import styles from '../index.module.css';

/*
  New caption treatment specific to blog.
*/
export function MediaCaption({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  return <figcaption className={styles.caption}>{children}</figcaption>;
}
