import React, { type JSX } from 'react';
import { clsx } from 'clsx';
import type { CSSProperties } from 'react';
import type { WithTypeProps } from 'geist/tmp/utils';
import { withType } from 'geist/tmp/utils';

type TextComponents =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'small'
  | 'code'
  | 'span'
  | 'div';

type WrapComponents = 'mark' | 'u' | 's' | 'b' | 'i';

type Components = keyof Pick<JSX.IntrinsicElements, TextComponents>;
type WrapTags = keyof Pick<JSX.IntrinsicElements, WrapComponents>;

export type Preset =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'body-1'
  | 'body-2'
  | 'body-title'
  | 'label'
  | 'small'
  | 'span';
type Weight = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

// Conditionally wrap a React element with another
/* TODO: Fix ESLint Error (#13355) */

function wrap(
  // eslint-disable-next-line @typescript-eslint/default-param-last -- Style Guide V4 Upgrade
  needed = false,
  children: React.ReactNode,
  tag: WrapTags,
): React.ReactNode {
  if (!needed) return children;

  /* eslint-disable-next-line import/no-named-as-default-member -- TODO: Fix ESLint Error (#13355) */
  return React.createElement(tag, {}, children);
}

interface Modifiers {
  mark?: boolean;
  underline?: boolean;
  strike?: boolean;
  bold?: boolean;
  italic?: boolean;
}

// Wrap the text in modifier elements like bold and italics
const wrapModifiers = (
  component: React.ReactNode,
  { mark, underline, strike, bold, italic }: Modifiers,
): React.ReactNode => {
  let result = component;

  result = wrap(mark, result, 'mark');
  result = wrap(underline, result, 'u');
  result = wrap(strike, result, 's');
  result = wrap(bold, result, 'b');
  result = wrap(italic, result, 'i');

  return result;
};

interface NestedProps {
  preset?: Preset;
  code?: boolean;
  Component?: Components;
  type?: string;
  className?: string;
  noMargin?: boolean;
  weight?: Weight;
  uppercase?: boolean;
  capitalize?: boolean;
  center?: boolean;
  maxWidth?: string;
  style?: CSSProperties;
  id?: string;
  children?: React.ReactNode;
}

function getComponent(defaultElement: Components): React.FC<NestedProps> {
  /* TODO: Fix ESLint Error (#13355) */
  /* eslint-disable-next-line react/function-component-definition */
  const C: React.FC<NestedProps> = ({
    noMargin,
    weight,
    code,
    uppercase,
    capitalize,
    center,
    Component = defaultElement,
    children,
    className,
    preset,
    maxWidth,
    style,
    ...props
  }) => {
    return (
      <Component
        className={clsx(
          className,
          'geist-text',
          {
            'geist-text-no-margin': noMargin,
            'geist-text-mono': code,
            'geist-text-upper': uppercase,
            'geist-text-capitalize': capitalize,
            'geist-text-center': center,
          },
          preset || defaultElement,
          weight ? `w-${weight}` : undefined,
        )}
        style={{
          maxWidth,
          ...style,
        }}
        {...props}
      >
        {children}
      </Component>
    );
  };

  /* eslint-disable-next-line prefer-template -- TODO: Fix ESLint Error (#13355) */
  C.displayName = 'Text_' + defaultElement;

  return C;
}

export const H1 = getComponent('h1');
export const H2 = getComponent('h2');
export const H3 = getComponent('h3');
export const H4 = getComponent('h4');
export const H5 = getComponent('h5');
export const H6 = getComponent('h6');
export const P = getComponent('p');
export const Small = getComponent('small');
export const Span = getComponent('span');

const components = [H1, H2, H3, H4, H5, H6, P, Small, Span];

interface DefaultProps extends WithTypeProps {
  Component?: Components;
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h5?: boolean;
  h6?: boolean;
  p?: boolean;
  small?: boolean;
  span?: boolean;
  mark?: boolean;
  underline?: boolean;
  strike?: boolean;
  bold?: boolean;
  italic?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
  weight?: Weight;
  style?: React.CSSProperties;
  title?: string;
}

export type TextProps = DefaultProps & NestedProps;

function Text({
  // HTML element
  Component,
  // styling
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  small,
  span,
  // wrapper
  mark,
  underline,
  strike,
  bold,
  italic,
  // react
  children,
  ...props
}: TextProps): JSX.Element {
  const Styler =
    components[[h1, h2, h3, h4, h5, h6, p, small, span].indexOf(true)] || P;

  return (
    <Styler Component={Component} {...props}>
      {wrapModifiers(children, { mark, underline, strike, bold, italic })}
    </Styler>
  );
}

/**
 * @deprecated Please use `Text` from `geist/components`.
 *
 * @example
 * <caption>To migrate type="secondary"</caption>
 * \<Text color='accents-6' weight=\{400\} size=\{16\}\>Deprecated no more!</Text>
 */
/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default withType(Text, { hasFill: false });
