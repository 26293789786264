import type { JSX } from 'react';
import type { LinkProps as GeistLinkProps } from 'geist/components';
import { Link as GeistLink } from '@pyra/multi-zone/link';

type LinkProps = Omit<JSX.IntrinsicElements['a'], 'ref'> &
  Omit<GeistLinkProps, 'passHref'> & {
    // Require content for accessibility.
    children: React.ReactNode;
  };

/**
 * A wrapper around Next's Link component and an anchor tag.
 */
export function Link({
  as,
  children,
  href,
  locale,
  prefetch,
  replace,
  scroll,
  shallow,
  ...props
}: LinkProps): JSX.Element {
  return (
    <GeistLink
      as={as}
      href={href}
      locale={locale}
      passHref
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      {...props}
    >
      {children}
    </GeistLink>
  );
}
