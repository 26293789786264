import type { Block, Inline } from '@contentful/rich-text-types';
import { isKeyboardInput } from '../helpers/type-guards';
import { components } from './component-list';

/**
 * There's no evidence of keyboard being used in Contentful, but this code is staying in case it's getting called somewhere else.
 */
export function EmbeddedEntryInline(node: Block | Inline): React.ReactNode {
  if (isKeyboardInput(node)) {
    const { Kbd } = components;
    const { metaModifier, shiftModifier, altModifier, controlModifier, value } =
      node.data.target.fields;

    return (
      <Kbd
        alt={altModifier}
        ctrl={controlModifier}
        meta={metaModifier}
        shift={shiftModifier}
      >
        {value}
      </Kbd>
    );
  }

  return null;
}
