/* TODO: This file uses styled jsx. If it's converted to CSS modules it may not need this directive. */
'use client';

import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import {
  ChevronRight as IconChevronRight,
  Plus as IconPlus,
} from 'geist/icons';
import Text, { H4 } from '#/app/components/geist/text/legacy';
import { Link } from '#/app/components/link';

export function IconCard({
  href,
  buttonHref,
  icon,
  label,
  width,
  arrowed,
  plus,
}) {
  return (
    <Link
      className={clsx('icon-card', { button: Boolean(buttonHref) })}
      href={href}
    >
      {icon ? <span className="icon">{icon}</span> : null}
      {label}
      {arrowed ? (
        <span className="arrow">
          <IconChevronRight />
        </span>
      ) : null}
      {plus ? (
        <span className="plus">
          <IconPlus />
        </span>
      ) : null}
      <style jsx>{`
        .icon-card {
          background: var(--geist-background);
          transition: box-shadow 0.15s ease;
          box-shadow: var(--shadow-smallest);
          height: 80px;
          padding: 0 24px;
          display: flex;
          align-items: center;
          border-radius: var(--geist-marketing-radius);
          cursor: pointer;
          font-weight: 500;
          color: var(--geist-foreground);
          text-decoration: none;
          width: ${width ? `${width}px` : 'auto'};
          gap: 5px;
        }

        .icon-card:hover {
          box-shadow: var(--shadow-extra-small);
        }

        :global(.dark-theme) .icon-card:hover {
          box-shadow: 0 0 0 1px var(--geist-foreground);
        }

        .arrow {
          margin-left: auto;
          color: var(--accents-3);
          transition: color 0.12s ease;
        }

        .plus {
          background: var(--geist-success);
          border-radius: 5px;
          margin-left: auto;
          color: var(--geist-background);
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.12s ease;
        }

        .icon-card:hover .plus {
          background: var(--geist-success-dark);
        }

        .icon-card:hover .arrow {
          color: var(--accents-6);
        }

        span {
          display: flex;
        }

        @media screen and (max-width: 1440px) {
          .icon-card {
            padding: 0 10px;
          }
        }

        @media screen and (max-width: 1280px) {
          .icon-card {
            padding: 0 24px;
          }
        }

        @media screen and (max-width: 1000px) {
          .icon-card {
            padding: 0 10px;
          }
        }

        @media screen and (max-width: 950px) {
          .icon-card {
            padding: 0 24px;
          }
        }

        @media screen and (max-width: 720px) {
          .icon-card {
            padding: 0 10px;
          }
        }

        @media screen and (max-width: 600px) {
          .icon-card {
            padding: 0 24px;
          }
        }
      `}</style>
    </Link>
  );
}

IconCard.propTypes = {
  arrowed: PropTypes.bool,
  buttonHref: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  plus: PropTypes.bool,
  width: PropTypes.number,
};

/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default function Card({
  href = '#',
  as,
  children,
  className,
  title,
  inConcept,
  ...props
}) {
  return (
    <div
      className={clsx('card', className, inConcept && 'in-concept')}
      {...props}
    >
      <Link as={as} href={href}>
        {title ? <H4>{title}</H4> : null}
        <Text small>{children}</Text>
      </Link>

      <style jsx>{`
        .card {
          background: var(--geist-background);
          border-radius: var(--geist-marketing-radius);
          box-shadow: var(--shadow-smallest);
          min-height: 48px;
          margin: 24px 0;
          transition: box-shadow 0.15s ease;
        }

        .card.in-concept {
          margin-top: 40px;
        }

        .card :global(p) {
          margin: 0;
        }

        .card :global(a) {
          display: flex;
          padding: 24px;
          width: 100%;
          height: 100%;
          flex-direction: column;
          font-size: var(--font-size-small);
          line-height: var(--line-height-small);
          color: var(--accents-5);
          text-decoration: none;
          transition: color 0.12s ease;
        }

        .card :global(h4) {
          margin-top: 0;
          margin-bottom: 4px;
          color: var(--geist-foreground);
          font-weight: 500;
          font-size: var(--font-size-primary);
          line-height: var(--line-height-primary);
        }

        .card:hover :global(a) {
          text-decoration: none;
        }

        .card:hover {
          text-decoration: none;
          box-shadow: var(--shadow-extra-small);
        }

        :global(.dark-theme) .card:hover {
          box-shadow: 0 0 0 1px var(--geist-foreground);
        }
      `}</style>
    </div>
  );
}

Card.propTypes = {
  as: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
};
