import type { JSX } from 'react';
import type { TwoColumnsContentNode } from '../../../types';
import styles from '../../index.module.css';
/* eslint-disable-next-line import/no-cycle -- refactored from single giant file */
import { textBlock } from '..';

export function TwoColumnsContent(node: TwoColumnsContentNode): JSX.Element {
  const { data } = node;
  return (
    <div className={styles.twoColumnsContent}>
      <div>{textBlock(data.target.fields.column1)}</div>
      <div>{textBlock(data.target.fields.column2)}</div>
    </div>
  );
}
