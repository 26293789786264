import type { Block, Inline } from '@contentful/rich-text-types';
import type { GenericContentfulNode } from '../../types';

function hasContentType(node: Block | Inline): node is GenericContentfulNode {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- inherited anys from contentful library
  return node.data.target?.sys?.contentType?.sys?.id;
}

export function getMediaType(node: Block | Inline): string {
  return hasContentType(node)
    ? node.data.target.sys.contentType.sys.id
    : 'Unknown';
}
