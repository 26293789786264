import type { JSX } from 'react';
import { assetHandler } from '../../helpers/asset-handler';
import type { ModeDependentMediaNode } from '../../../types';
import { components } from '../component-list';

export function ModeDependentMedia(props: ModeDependentMediaNode): JSX.Element {
  const {
    lightVersion: { fields: lightVersionFields },
    darkVersion: { fields: darkVersionFields },
  } = props.data.target.fields;

  return (
    <components.ThemeHelper
      dark={assetHandler(darkVersionFields)}
      light={assetHandler(lightVersionFields)}
    />
  );
}
