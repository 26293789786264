import React, { type JSX } from 'react';
import { clsx } from 'clsx';
import type { Polymorphic } from './forward-ref';
import { forwardRef } from './forward-ref';
import type { AcceptedTags } from './web-jsx-types';

export type WithTypeTypes =
  | 'secondary'
  | 'tertiary'
  | 'warning'
  | 'success'
  | 'default'
  | 'alert'
  | 'error'
  | 'lite'
  | 'ghost'
  | 'alert'
  | 'violet';

interface WithTypeOptions {
  hasFill?: boolean;
  defaultFill?: boolean;
}

export interface WithTypeProps {
  Component?: AcceptedTags;
  className?: string;
  type?: WithTypeTypes;
  fill?: boolean;
  children?: React.ReactNode;
}

interface ForwardedRefProps {
  forwardedRef?: React.Ref<HTMLElement>;
}

const getCn = (
  type?: WithTypeTypes,
  fill?: boolean,
): (string | null)[] | null => {
  if (!type) return null;
  return ['geist-themed', `geist-${type}`, fill ? `geist-${type}-fill` : null];
};

export const withType = <P extends WithTypeProps>(
  Component: React.ComponentType<P>,
  opts: WithTypeOptions = {},
): Polymorphic<React.ElementType, P & ForwardedRefProps> => {
  const { defaultFill, hasFill } = opts;

  function Comp({
    className,
    forwardedRef,
    ...props
  }: P & ForwardedRefProps): JSX.Element {
    // Do not immediately destructure these props, they should still be passed
    const { type } = props;
    let fill = props.fill || defaultFill;

    // Disable fill styling if hasFill option is false
    if (hasFill === false) {
      fill = defaultFill || false;
    }

    // Combine any possible className prop and the generated .geist-themed classnames
    const classNames = clsx(getCn(type, fill), className);

    return (
      <Component className={classNames} {...(props as P)} ref={forwardedRef} />
    );
  }

  const forwardRefComponent: React.ForwardRefRenderFunction<
    HTMLElement,
    P & ForwardedRefProps
  > = (props, ref) => {
    return <Comp {...props} forwardedRef={ref} />;
  };

  // Name for React DevTools
  forwardRefComponent.displayName = Component.displayName || Component.name;

  return forwardRef(forwardRefComponent);
};
