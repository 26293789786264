'use client';

import React, { useRef, useState, type JSX } from 'react';
import { clsx } from 'clsx';
import type { PressEvent } from '@react-types/shared';
import { tid } from '@vercel/geist-test-utils';
import { Check } from '../../new-icons/16/check';
import { Copy } from '../../new-icons/16/copy';
import { Button } from '../button';
import { CustomButton } from '../custom-button';
import { useToasts } from '../toast';
import type { ButtonProps } from '../button';
import type { CustomButtonProps } from '../custom-button';
import styles from './copy-button.module.css';

interface CopyButtonProps {
  textToCopy: string;
  label: string;
  title?: string;
  copied?: boolean;
  className?: string;
  type?: ButtonProps['type'];
  variant?: ButtonProps['variant'];
  normal?: CustomButtonProps['normal'];
  hover?: CustomButtonProps['hover'];
  active?: CustomButtonProps['active'];
  shape?: ButtonProps['shape'];
  icon?: React.ReactNode;
  size?: ButtonProps['size'];
  iconSize?: number;
  disabled?: ButtonProps['disabled'];
  onClick?: ButtonProps['onClick'];
}

/* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
const CopyButton = ({
  className,
  textToCopy,
  onClick,
  label,
  title,
  type = 'secondary',
  variant,
  normal,
  hover,
  active,
  shape = 'square',
  size,
  icon,
  ...props
}: CopyButtonProps): JSX.Element => {
  const toasts = useToasts();
  const [isCopied, setIsCopied] = useState(false);
  const [initial, setInitial] = useState(true);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  /* eslint-disable-next-line import/no-named-as-default-member -- TODO: Fix ESLint Error (#13355) */
  const onCopy = React.useCallback(
    (e: PressEvent) => {
      setInitial(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          setIsCopied(true);
          timeoutRef.current = setTimeout(() => {
            setIsCopied(false);
          }, 1000);
        })
        .catch(() => toasts.error('Failed to copy to clipboard'))
        .finally(() => onClick?.(e));
    },
    [textToCopy, toasts, onClick],
  );

  /* eslint-disable-next-line no-implicit-coercion -- TODO: Fix ESLint Error (#13355) */
  const isCustomButton = !!(normal || hover || active);

  const copyIcons = (
    <div style={{ position: 'relative', height: 16, width: 16 }}>
      <div
        className={clsx(styles.icon, {
          [String(styles.initial)]: initial,
          [String(styles.visible)]: !initial && isCopied,
          [String(styles.hidden)]: !initial && !isCopied,
        })}
      >
        <Check />
      </div>
      <div
        className={clsx(styles.icon, {
          [String(styles.visible)]: !initial && !isCopied,
          [String(styles.hidden)]: !initial && isCopied,
        })}
      >
        {icon ? icon : <Copy />}
      </div>
    </div>
  );

  if (isCustomButton) {
    return (
      <CustomButton
        active={active}
        aria-label={label}
        className={clsx(className)}
        data-testid={tid('copy', 'button')}
        hover={hover}
        normal={normal}
        onClick={onCopy}
        shape={shape}
        size={size}
        svgOnly
        title={title}
        typeName="button"
        {...props}
      >
        {copyIcons}
      </CustomButton>
    );
  }

  return (
    <Button
      aria-label={label}
      className={clsx(className)}
      data-testid={tid('copy', 'button')}
      onClick={onCopy}
      shape={shape}
      size={size}
      svgOnly
      title={title}
      type={type}
      typeName="button"
      variant={variant}
      {...props}
    >
      {isCopied ? (
        <div aria-live="assertive" className="geist-sr-only" role="status">
          Copied!
        </div>
      ) : null}

      {copyIcons}
    </Button>
  );
};

export { CopyButton };
