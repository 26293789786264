import { clsx } from 'clsx';
import type { PropsWithRef } from 'react';
import React from 'react';
import { Button } from '../button/button';
import type { ButtonProps } from '../button/button';
import styles from './custom.module.css';

interface StyleObject {
  border?: string;
  foreground?: string;
  background?: string;
}
export interface Styles {
  normal?: StyleObject;
  active?: StyleObject;
  hover?: StyleObject;
}

export type CustomButtonProps = PropsWithRef<ButtonProps> & Styles;

/* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
export const CustomButton: React.FC<CustomButtonProps> = ({
  normal,
  active,
  hover,
  className,
  ...props
}) => {
  return (
    <Button
      {...props}
      className={clsx(styles.button, className)}
      data-custom-button=""
      style={
        {
          '--button-custom-fg': `${normal?.foreground || ''}`,
          '--button-custom-bg': `${normal?.background || ''}`,
          '--button-custom-border': `${normal?.border || ''}`,

          '--button-custom-fg-active': `${active?.foreground || ''}`,
          '--button-custom-bg-active': `${active?.background || ''}`,
          '--button-custom-border-active': `${active?.border || ''}`,

          '--button-custom-fg-hover': `${hover?.foreground || ''}`,
          '--button-custom-bg-hover': `${hover?.background || ''}`,
          '--button-custom-border-hover': `${hover?.border || ''}`,
        } as React.CSSProperties
      }
    >
      {props.children}
    </Button>
  );
};
