'use client';
import React, { useContext } from 'react';

/* eslint-disable-next-line import/no-named-as-default-member -- TODO: Fix ESLint Error (#13355) */
export const DisabledContext = React.createContext<boolean>(false);
DisabledContext.displayName = 'DisabledContext';

export const useDisabled = (disabled?: boolean): boolean => {
  const ctx = useContext(DisabledContext);
  return disabled ?? ctx;
};
