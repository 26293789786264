export function getHeadingSidebarText(heading: string): string {
  return (
    heading
      .replace(/<\/?h[1-3]>/gi, '')
      .replace(/<\/?code>/gi, '')
      .replace(/&#x27;/gi, "'")
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>')
      .replace(/&#40;/gi, '(')
      .replace(/&#41;/gi, ')')
      //.replace(/\//gi, '-')
      .replace(/<\/?em>/gi, '')
      .replace(/<\/-em>/gi, '')
      .replace(/<-em>/gi, '')
      .replace(/\(/gi, '')
      .replace(/\)/gi, '')
      //.replace(/\\|\//g,'')
      .replace(/&amp;/gi, 'and')
  );
}
