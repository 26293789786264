import type { Block, Inline } from '@contentful/rich-text-types';
import type { JSX } from 'react';
import {
  isCaption,
  isCode,
  isCodeSandbox,
  isEvaluatedCode,
  isLinkedMedia,
  isMobileAndModeDependentMedia,
  isModeDependentMedia,
  isNote,
  isTwoColumnsContent,
} from '../helpers/type-guards';
/* eslint-disable import/no-cycle -- refactored from single giant file */
import { Caption } from './embedded-entries/caption';
import { Code } from './embedded-entries/code';
import { CodeSandbox } from './embedded-entries/code-sandbox';
import { EvaluatedCode } from './embedded-entries/evaluated-code';
import { LinkedMedia } from './embedded-entries/linked-media';
import { MobileAndModeDependentMedia } from './embedded-entries/mobile-and-mode-dependent-media';
import { ModeDependentMedia } from './embedded-entries/mode-dependent-media';
import { Note } from './embedded-entries/note';
import { TwoColumnsContent } from './embedded-entries/two-columns-content';
/* eslint-enable import/no-cycle */

export function EmbeddedEntryBlock(node: Block | Inline): JSX.Element | null {
  if (isMobileAndModeDependentMedia(node)) {
    return <MobileAndModeDependentMedia {...node} />;
  }

  if (isModeDependentMedia(node)) {
    return <ModeDependentMedia {...node} />;
  }

  if (isCaption(node)) {
    return <Caption {...node} />;
  }

  if (isNote(node)) {
    return <Note {...node} />;
  }

  if (isCode(node)) {
    return <Code {...node} />;
  }

  if (isLinkedMedia(node)) {
    return <LinkedMedia {...node} />;
  }

  if (isEvaluatedCode(node)) {
    return <EvaluatedCode {...node} />;
  }

  if (isCodeSandbox(node)) {
    return <CodeSandbox {...node} />;
  }

  if (isTwoColumnsContent(node)) {
    return <TwoColumnsContent {...node} />;
  }

  return null;
}
