import { Image } from 'geist/components';
import type { JSX } from 'react';
import styles from '../index.module.css';
import { MediaCaption } from '../components/media-caption';

export const assetHandler = (
  fields: {
    description: string;
    file: {
      contentType?: string;
      fileName: string;
      url: string;
      details: { image?: { width: number; height: number } };
    };
  },
  options: {
    dottedBackground?: boolean;
    imagePercentageWidth: number;
    fullWidth: boolean;
  } = {
    dottedBackground: true,
    imagePercentageWidth: 100,
    fullWidth: false,
  },
): JSX.Element | null => {
  const {
    description,
    file: { contentType = '', fileName, url, details },
  } = fields;

  if (contentType.startsWith('image/')) {
    const { image } = details;

    if (!image) {
      return null;
    }
    const { height, width } = image;

    /* eslint-disable-next-line no-nested-ternary -- TODO: Fix ESLint Error (#13355) */
    const imageWidth = options.fullWidth
      ? 1280
      : options.imagePercentageWidth
        ? Math.floor((options.imagePercentageWidth / 100) * (width / 2))
        : width / 2;

    const imageHeight = options.imagePercentageWidth
      ? Math.floor((options.imagePercentageWidth / 100) * (height / 2))
      : height / 2;

    if (options.fullWidth) {
      return (
        <figure
          className={styles['image-wrapper']}
          data-vercel-edit-target
          style={{ backgroundImage: 'none' }}
        >
          <div className={styles['full-width-image-container']}>
            <div
              className={styles['full-width-image']}
              style={{
                ['--width' as string]: width,
                ['--height' as string]: height,
              }}
            >
              <div className={styles['full-width-image-inner']}>
                <Image
                  alt={description || fileName}
                  height={width * ((height || 1) / (width || 1))}
                  srcDark={`https:${url}`}
                  srcLight={`https:${url}`}
                  width={width}
                />
              </div>
            </div>
          </div>
          {description ? <MediaCaption>{description}</MediaCaption> : null}
        </figure>
      );
    }

    return (
      <figure
        className={styles['image-wrapper']}
        data-vercel-edit-target
        style={{
          ...(!options.dottedBackground && {
            backgroundImage: 'none',
          }),
        }}
      >
        <div
          className={styles.image}
          style={{
            ...(options.imagePercentageWidth && {
              maxWidth: `${Math.floor(
                (options.imagePercentageWidth / 100) * 800,
              )}px`,
            }),
          }}
        >
          <Image
            alt={description || fileName}
            height={imageHeight}
            srcDark={`https:${url}`}
            srcLight={`https:${url}`}
            width={imageWidth}
          />
        </div>
        {description ? <MediaCaption>{description}</MediaCaption> : null}
      </figure>
    );
  }

  return null;
};
