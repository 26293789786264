/**
 * Type guards for various contentful node types. Useful for narrowing and for passing a given node to the right renderer.
 */
import type { Block, Inline } from '@contentful/rich-text-types';
import type {
  AccordionNode,
  CaptionNode,
  CardGrideNode,
  CodeNode,
  CodeSandboxNode,
  CustomerLogosNode,
  EvaluatedCodeNode,
  KeyboardInputNode,
  LinkedMediaNode,
  MobileAndModeDependentMediaNode,
  ModeDependentMediaNode,
  NoteNode,
  PartnerQuoteNode,
  QuoteNode,
  TweetNode,
  TwoColumnsContentNode,
  YouTubeVideoNode,
  MarketingVisualNode,
} from '../../types';
import { getMediaType } from './get-media-type';

export function isQuote(node: Block | Inline): node is QuoteNode {
  return getMediaType(node) === 'quote';
}

export function isPartnerQuote(node: Block | Inline): node is PartnerQuoteNode {
  return getMediaType(node) === 'partnerQuote';
}

export function isMobileAndModeDependentMedia(
  node: Block | Inline,
): node is MobileAndModeDependentMediaNode {
  return getMediaType(node) === 'mobileAndModeDependentMedia';
}

export function isModeDependentMedia(
  node: Block | Inline,
): node is ModeDependentMediaNode {
  return getMediaType(node) === 'modeDependentMedia';
}

export function isCustomerLogos(
  node: Block | Inline,
): node is CustomerLogosNode {
  return getMediaType(node) === 'customerLogos';
}
export function isCaption(node: Block | Inline): node is CaptionNode {
  return getMediaType(node) === 'caption';
}

export function isNote(node: Block | Inline): node is NoteNode {
  return getMediaType(node) === 'note';
}

export function isCode(node: Block | Inline): node is CodeNode {
  return getMediaType(node) === 'code';
}

export function isYouTubeVideo(node: Block | Inline): node is YouTubeVideoNode {
  return getMediaType(node) === 'youTubeVideo';
}

export function isLinkedMedia(node: Block | Inline): node is LinkedMediaNode {
  return getMediaType(node) === 'linkedMedia';
}

export function isTweet(node: Block | Inline): node is TweetNode {
  return getMediaType(node) === 'tweet';
}

export function isEvaluatedCode(
  node: Block | Inline,
): node is EvaluatedCodeNode {
  return getMediaType(node) === 'evaluatedCode';
}

export function isCardGrid(node: Block | Inline): node is CardGrideNode {
  return getMediaType(node) === 'cardGrid';
}

export function isCodeSandbox(node: Block | Inline): node is CodeSandboxNode {
  return getMediaType(node) === 'codeSandbox';
}

export function isTwoColumnsContent(
  node: Block | Inline,
): node is TwoColumnsContentNode {
  return getMediaType(node) === 'twoColumnsContent';
}

export function isAccordion(node: Block | Inline): node is AccordionNode {
  return getMediaType(node) === 'accordion';
}

export function isKeyboardInput(
  node: Block | Inline,
): node is KeyboardInputNode {
  return getMediaType(node) === 'keyboardInput';
}

export function isMarketingVisual(
  node: Block | Inline,
): node is MarketingVisualNode {
  return getMediaType(node) === 'marketingVisual';
}
