import React, { type JSX } from 'react';

/**
 * `As` represents the union of JSX elements that can be rendered
 */
type As = React.ElementType;

/**
 *  Get the props of a component type, add the polymorhic `Component` prop
 */
type PropsOf<ElementType extends As> =
  React.ComponentPropsWithoutRef<ElementType> & {
    Component?: As;
  };

/**
 * Type annotation for the `Object.assign` idea
 */
type ObjectAssign<Source, Target> = Omit<Source, keyof Target> & Target;

/**
 * Type merges the props of the default element type, and the props of the element used in the `Component` prop
 */
type Merge<
  DefaultElementProps,
  PolymorphicElementProps,
  UserDefinedProps,
  PolymorphicElement extends As = As,
> = ObjectAssign<DefaultElementProps, UserDefinedProps> &
  ObjectAssign<PolymorphicElementProps, UserDefinedProps> & {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Fix ESLint Error (#13355) */
    Component?: UserDefinedProps extends { Component?: any }
      ? PolymorphicElement | UserDefinedProps['Component']
      : PolymorphicElement;
  };

/**
 * Type annotation that defines the interface for a polymorphic component.
 * A polymorphic component is a component whose base element type can be changed by the `Component` prop.
 *
 * @example
 * ```jsx
 * <Text as="a" />
 * ```
 *
 * The `Text` component is a polymorphic component, and we can infer the props of the component should be:
 * - props of `Text` + props of `a`
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type Polymorphic<
  ElementType extends As,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Fix ESLint Error (#13355) */
  UserDefinedProps = Record<string, any>,
> = {
  <PolymorphicElement extends As>(
    props: Merge<
      React.ComponentProps<ElementType>,
      React.ComponentProps<PolymorphicElement>,
      UserDefinedProps,
      PolymorphicElement
    >,
  ): JSX.Element;
  displayName?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Fix ESLint Error (#13355) */
  propTypes?: React.WeakValidationMap<any>;
};

/**
 * Custom forward ref implementation with support for polymorphic `Component` prop
 */
export function forwardRef<UserDefinedProps, ElementType extends As>(
  component: React.ForwardRefRenderFunction<
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Fix ESLint Error (#13355) */
    any,
    ObjectAssign<PropsOf<ElementType>, UserDefinedProps> & { Component?: As }
  >,
): Polymorphic<React.ElementType, UserDefinedProps> {
  // eslint-disable-next-line import/no-named-as-default-member
  return React.forwardRef(component) as unknown as Polymorphic<
    ElementType,
    UserDefinedProps
  >;
}
