import type { JSX } from 'react';
import type { CaptionNode } from '../../../types';
import { components } from '../component-list';
/* eslint-disable-next-line import/no-cycle -- refactored from single giant file */
import { textBlock } from '..';
import { stripWrappingParagraph } from '../../helpers/strip-wrapping-paragraph';

export function Caption(props: CaptionNode): JSX.Element {
  const { text } = props.data.target.fields;
  const doc = { ...text };

  // Strip away the wrapping paragraph
  stripWrappingParagraph(doc);
  return <components.Caption>{textBlock(doc)}</components.Caption>;
}
