import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { handleImport } from '@pyra/promise-utils/handle-import';
import type { EvaluatedCodeNode } from '../../../types';
import styles from '../../index.module.css';
import { components } from '../component-list';

export function EvaluatedCode(props: EvaluatedCodeNode): JSX.Element {
  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- TODO: Fix ESLint Error (#13355) */
  const ReactJSXParser = dynamic(
    // @ts-expect-error Type '{}' is not assignable to type 'ReactNode'.ts(2345) because upstream uses @types/react@16
    () => handleImport(import('@zeit/react-jsx-parser')),
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Fix ESLint Error (#13355) */
  ) as any;

  const { name, code } = props.data.target.fields;

  return (
    <div className={styles.evaluatedCodeWrapper}>
      <ReactJSXParser
        /* eslint-disable-next-line react/jsx-boolean-value -- TODO: Fix ESLint Error (#13355) */
        allowUnknownElements={true}
        bindings={{
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Fix ESLint Error (#13355) */
          isRowType: (_: any, idx: number): boolean => idx > 0,
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: Fix ESLint Error (#13355) */
          isHeadType: (_: any, idx: number): boolean => idx === 0,
        }}
        blacklistedTags={['script', 'style']}
        components={components}
        componentsOnly={false}
        jsx={code}
        key={name}
        onError={(err: Error): void => {
          /* eslint-disable-next-line no-console -- TODO: Fix ESLint Error (#13355) */
          console.log(err);
        }}
        renderInpost={false}
      />
    </div>
  );
}
