import React from 'react';
import { Badge } from 'geist/components';
import { clsx } from 'clsx';
import { Link as IconLink } from 'geist/icons';
import { getHeadingId } from '@pyra/docs-shared/utils/get-heading-id';
import styles from './linked-heading.module.css';

function Heading(props) {
  const { component, className, children, ...rest } = props;
  return React.cloneElement(
    component,
    {
      className: [className, component.props.className || ''].join(' '),
      ...rest,
    },
    children,
  );
}

function getTextFromNode(node) {
  if (typeof node === 'string') {
    return node;
  }

  if (Array.isArray(node)) {
    return node.map(getTextFromNode).join('');
  }

  if (!React.isValidElement(node)) {
    return '';
  }

  let text = '';
  React.Children.forEach(node.props.children, (child) => {
    text += getTextFromNode(child);
  });

  return text;
}

function LinkedHeading(props) {
  const {
    offsetTop,
    noAnchor,
    margin = true,
    containerStyle = true,
    prefix = '',
    joinHeading = false,
    level = 0,
    marketplaceBadge = false,
    onClick,
  } = props;
  const component = props.children;
  const children = component.props.children || '';

  let id = props.id;
  let text = children;

  if (!id) {
    text = getTextFromNode(children);
    id = getHeadingId(text);
  }

  let href;

  if (noAnchor) {
    href = `#${id}`;
  } else if (prefix.includes('#')) {
    href = `${prefix}/${id}`;
  } else {
    href = `${prefix}#${id}`;
  }

  let elId;

  if (prefix === '') {
    elId = `${id}`;
  } else if (prefix.includes('#')) {
    elId = `${prefix.split('#')[1]}/${id}`;
  } else {
    elId = `${prefix}#${id}`;
  }

  return (
    <div
      className={clsx(
        props.description ? styles['hasDescription'] : '',
        containerStyle ? styles.container : '',
      )}
    >
      <Heading
        className={styles.header}
        component={component}
        data-components-heading
        onClick={onClick}
      >
        <span
          className={clsx(styles.target, offsetTop ? styles.offsetTop : '')}
          id={elId}
        />
        {joinHeading && level === 2 ? (
          <a className={styles.title} href={href}>
            <h2>{children}</h2>
          </a>
        ) : null}
        {joinHeading && level === 3 ? (
          <a className={styles.title} href={href}>
            <h3>{children}</h3>
          </a>
        ) : null}
        {!joinHeading && margin ? (
          <a className={`${styles.title} [&>p]:m-0`} href={href}>
            {children}
          </a>
        ) : (
          !joinHeading && (
            <a className={`${styles.titleNoMargin} [&>p]:m-0`} href={href}>
              {children}
            </a>
          )
        )}
        {!joinHeading && (
          <span className={styles.permalink}>
            <IconLink size="0.6em" />
          </span>
        )}
        {joinHeading ? (
          <span className={styles.permalinkJoin}>
            <IconLink size="0.6em" />
          </span>
        ) : null}
        {marketplaceBadge ? (
          <Badge className={styles.pill} variant="secondary">
            Marketplace
          </Badge>
        ) : null}
      </Heading>
      {props.description ? (
        <span className={styles.description}>{props.description}</span>
      ) : null}
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default LinkedHeading;
