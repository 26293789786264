import { cn } from '#/lib/clx';

function LI({
  className,
  ...props
}: React.ComponentProps<'li'>): React.ReactElement {
  return (
    <li
      className={cn(
        'mb-[10px] leading-[var(--line-height-primary)] text-[var(--font-size-primary)] [&>p]:m-0',
        className,
      )}
      {...props}
    />
  );
}

/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default LI;
