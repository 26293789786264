import type { JSX } from 'react';
import { assetHandler } from '../../helpers/asset-handler';
import type { LinkedMediaNode } from '../../../types';
import { components } from '../component-list';

export function LinkedMedia(props: LinkedMediaNode): JSX.Element {
  const { MultiZoneLink } = components;

  const { url, media } = props.data.target.fields;
  return <MultiZoneLink href={url}>{assetHandler(media.fields)}</MultiZoneLink>;
}
