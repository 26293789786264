'use client';

import { memo, useMemo } from 'react';
import { clsx } from 'clsx';
import type { CSSProperties } from 'react';
import { CopyButton } from '../copy-button';
import type { UseTypeProps } from '../../utils';
import { useNewType } from '../../utils';
import styles from './snippet.module.css';

interface SnippetProps extends UseTypeProps {
  /* eslint-disable-next-line @typescript-eslint/array-type -- TODO: Fix ESLint Error (#13355) */
  text: string | Array<string> | React.ReactNode;
  copyText?: string;
  onCopy?: () => void;
  width?: number | string;
  prompt?: boolean;
  icon?: boolean;
  notFocusable?: boolean;
  dark?: boolean;
  className?: string;
  style?: CSSProperties;
  compact?: boolean; // matches geist input height
  placeholder?: string;
}

/* eslint-disable-next-line react/display-name -- TODO: Fix ESLint Error (#13355) */
const Snippet = memo(
  ({
    text,
    copyText,
    onCopy,
    width,
    compact = false,
    prompt = true,
    icon = true,
    dark,
    type,
    fill,
    className,
    variant,
    placeholder,
    notFocusable,
    ...props
  }: SnippetProps) => {
    const typeCn = useNewType(type, fill, variant);

    const textToCopy = useMemo(() => {
      // Allow passing a custom string to copy, if `text` has
      // rich text contents like <b>. Or you just want to copy something else
      if (copyText) {
        return copyText;
      } else if (Array.isArray(text)) {
        return text.join('\n');
      } else if (typeof text === 'string') {
        return text;
      }

      return '';
    }, [text, copyText]);

    const isUsingPlaceholder = Boolean(!text && placeholder);

    return (
      <div
        className={clsx(
          styles.snippet,
          {
            [String(styles.prompt)]: prompt,
            [String(styles.dark)]: dark,
            [String(styles.isUsingPlaceholder)]: isUsingPlaceholder,
          },
          className,
          typeCn,
        )}
        {...props}
        data-geist-snippet=""
        data-version="v1"
        style={{ width, height: compact ? '36px' : 'auto', ...props.style }}
      >
        {Array.isArray(text) ? (
          text.map((line, i) => (
            <pre
              className="geist-overflow-scroll-y"
              // Just need a unique key here
              /* eslint-disable-next-line react/no-array-index-key, @typescript-eslint/restrict-template-expressions -- TODO: Fix ESLint Error (#13355) */
              key={`snippet-${text}-${line}-${i}`}
            >
              {line}
            </pre>
          ))
        ) : (
          <pre
            className="geist-overflow-scroll-y"
            style={
              compact
                ? {
                    height: `36px`,
                    lineHeight: 1,
                  }
                : undefined
            }
          >
            {text ? text : placeholder}
          </pre>
        )}
        {icon ? (
          <CopyButton
            className={styles.copyIcon}
            disabled={notFocusable}
            label="Copy to clipboard"
            onClick={(): void => {
              onCopy?.();
            }}
            textToCopy={textToCopy}
          />
        ) : null}
      </div>
    );
  },
);

export { Snippet };
