import type { JSX } from 'react';
// eslint-disable-next-line import/no-default-export
export default function UL({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <ul className="my-3 ml-6 list-none p-0 [&>li]:before:absolute [&>li]:before:-ml-3.5 [&>li]:before:inline-block [&>li]:before:text-[var(--accents-4)] [&>li]:before:content-['–']">
      {children}
    </ul>
  );
}
