import type { JSX } from 'react';

interface ViewportHelperProps {
  light: React.ReactNode;
  dark: React.ReactNode;
}

/**
 * Serve correct media based on viewport and users light or dark mode.
 */
/* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
export const ViewportHelper = ({
  mobile,
  desktop,
}: {
  mobile: ViewportHelperProps;
  desktop: ViewportHelperProps;
}): JSX.Element => (
  <>
    <div className="geist-show-on-desktop">
      <ThemeHelper dark={desktop.dark} light={desktop.light} />
    </div>
    <div className="geist-hide-on-desktop">
      <ThemeHelper dark={mobile.dark} light={mobile.light} />
    </div>
  </>
);

/**
 * Serve correct media based on user's light or dark mode.
 * Media should use intersection observer to avoid
 * loading both light and dark media (img, video, etc).
 */
/* eslint-disable-next-line react/function-component-definition -- TODO: Fix ESLint Error (#13355) */
const ThemeHelper = ({ light, dark }: ViewportHelperProps): JSX.Element => (
  <>
    <div className="geist-hide-on-dark">{light}</div>
    <div className="geist-hide-on-light">{dark}</div>
  </>
);

/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default ThemeHelper;
